<template>
  <div id="main-sidebar">
    <div class="logo-container">
      <img class="tab-avtar" src="/images/logo-area.svg" alt="sidebar-logo" />
    </div>
    <div class="tab-container" v-for="(tab, index) in tabs" :key="index">
      <div
        :class="['tab', activetab === index ? 'active' : '']"
        :style="tab.notification_count ? 'justify-content: space-between' : ''"
        @click="selectTab(tab, index)"
      >
        <div style="display: flex; align-items: center">
          <img
            class="tab-avtar"
            :src="
              activetab === index
                ? `/images/${tab.active_icon}`
                : `/images/${tab.inactive_icon}`
            "
            :alt="tab.name"
          />
          <div class="tab-name">{{ tab.name }}</div>
        </div>
        <div class="comming-soon-text" v-if="tab.comming_soon">COMING SOON</div>
        <div class="notification-count" v-else-if="tab.notification_count">
          {{ tab.notification_count }}
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="user-info">
        <div class="avtar-container">
          <img
            v-if="$store.getters.user.image"
            :src="$store.getters.user.image"
            alt="avtar"
          />
          <span v-else>{{
            $store.getters.user.first_name.substring(0, 1)
          }}</span>
        </div>
        <div class="user-email">
          {{ $store.getters.user.first_name }}
          {{ $store.getters.user.last_name }}
        </div>
      </div>
      <div class="link-container">
        <div class="link" @click="logout">Log out</div>
        <div class="link" style="margin-left: 1rem" @click="settings">
          Settings
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 0,
      tabs: [
        {
          name: "Dashboard",
          active_icon: "dashboard-icon-active.svg",
          inactive_icon: "dashboard-icon-inactive.svg",
          link: "/dashboard",
        },
        {
          name: "Companies",
          active_icon: "companies-icon-active.svg",
          inactive_icon: "companies-icon-inactive.svg",
          link: "/companies",
        },
        {
          name: "Applications",
          active_icon: "applications-icon-active.svg",
          inactive_icon: "applications-icon-inactive.svg",
          link: "/applications",
        },
        {
          name: "All Analysis",
          active_icon: "applications-icon-active.svg",
          inactive_icon: "applications-icon-inactive.svg",
          link: "/applicationDetails",
        },
        {
          name: "Onboarding Applications",
          active_icon: "applications-icon-active.svg",
          inactive_icon: "applications-icon-inactive.svg",
          link: "/OnboardingApplication",
        },
        // {
        //   name: "Reports",
        //   active_icon: "report-icon.png",
        //   inactive_icon: "report-icon.png",
        //   link: "/reports",
        //   comming_soon: true,
        // },
        {
          name: "Repayments",
          active_icon: "payments-icon-active.svg",
          inactive_icon: "payments-icon-inactive.svg",
          link: "/repayments",
        },
        {
          name: "Help Requests",
          active_icon: "help-active.svg",
          inactive_icon: "help-inactive.svg",
          link: "/help",
        },

        // {
        //   name: "Notifications",
        //   active_icon: "notification-icon-active.png",
        //   inactive_icon: "notification-icon-inactive.png",
        //   notification_count: 2,
        //   link: "/notifications",
        // },
      ],
    };
  },
  methods: {
    selectTab(tab, index) {
      if (this.activetab != index) {
        this.activetab = index;
        this.$router.push(tab.link);
      }
    },
    logout() {
      this.$router.push("/logout");
    },
    settings() {
      this.$router.push("/settings");
    },
  },
  watch: {
    $route() {
      const actualtab = this.tabs.findIndex(
        (each) => each.link === this.$route.path
      );
      if (this.activetab !== actualtab) this.activetab = actualtab;
    },
  },
  mounted() {
    this.activetab = this.tabs.findIndex(
      (each) => each.link === this.$route.path
    );
  },
};
</script>

<style scoped>
#main-sidebar {
  height: 100vh;
  background-color: #024058;
  position: relative;
}
#main-sidebar .tab-container {
  width: 100%;
}
#main-sidebar .tab.active {
  color: #024058;
  background-color: #b4e7e2;
}
#main-sidebar .tab.comming-soon {
  cursor: not-allowed;
}
#main-sidebar .tab {
  padding: 1.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#main-sidebar .active .tab-name {
  color: #024058;
}
#main-sidebar .comming-soon .tab-name {
  color: #dfddd9;
}
#main-sidebar .tab-name {
  margin-left: 1rem;
  font-size: 16px;
  font-family: Roboto_Medium;
  color: #b4e7e2;
}
#main-sidebar .notification-count {
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #ffffff;
  background-color: #d91f3c;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#main-sidebar .comming-soon-text {
  font-size: 11px;
  font-family: Roboto_Medium;
  color: #bab8b9;
}
#main-sidebar .logo-container {
  background-color: #024058;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#main-sidebar .bottom-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #f0efed;
}
#main-sidebar .user-info {
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
}
#main-sidebar .avtar-container {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #ebdfe5;
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #024058;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
#main-sidebar .user-email {
  margin-left: 1rem;
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #b4e7e2;
}
#main-sidebar .link-container {
  padding: 0.5rem 1rem 1rem 1rem;
  display: flex;
  align-items: center;
}
#main-sidebar .link {
  cursor: pointer;
  font-size: 15px;
  color: #b4e7e2;
}
#main-sidebar .link:hover {
  text-decoration: underline;
}
.link {
  text-decoration: none;
  cursor: pointer;
}
</style>
