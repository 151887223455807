<template>
  <div id="dashboard-container">
    <div v-if="querying">
      <common-loading />
    </div>
    <div v-else>
      <b-alert :show="error && error.length ? true : false" variant="danger">
        <div v-html="error || '&nbsp;'"></div>
      </b-alert>

      <b-row v-if="facility_report" style="padding: 0rem 0.5rem">
        <b-col style="padding: 0rem 0.25rem">
          <div
            class="block"
            style="background-color: transparent; padding: 0rem"
          >
            <div class="title">Hello {{ $store.getters.user.first_name }}</div>
            <div class="user-role">Admin</div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block">
            <div class="label">FACILITY RAISED</div>
            <div class="value">
              <span v-html="$amount(raised?.raised_value || 0)"></span>
            </div>
            <div class="sub-title"></div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block">
            <div class="label">FACILITY EXTENDED</div>
            <div class="value">
              <span v-html="$amount(facility_report.extended)"></span>
            </div>
            <div class="sub-title">
              Sale Price:
              <span v-html="$amount(facility_report.sale_price)"></span>
            </div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block">
            <div class="label">UNUTALIZED</div>
            <div class="value">
              <span v-html="$amount(facility_report.unutilized)"></span>
            </div>
            <div class="sub-title"></div>
          </div>
        </b-col>
        <b-col style="padding: 0rem 0.25rem">
          <div class="block">
            <div class="label">OUTSTANDING</div>
            <div class="value">
              <span v-html="$amount(facility_report.outstanding)"></span>
            </div>
            <div class="sub-title"></div>
          </div>
        </b-col>
      </b-row>

      <b-row style="margin-top: 1rem">
        <b-col lg="12" sm="12">
          <div class="content-block">
            <div
              class="total-title"
              style="padding-bottom: 1rem; border-color: transparent"
            >
              Recently Registered
            </div>
            <div>
              <div
                class="company-block"
                v-for="(each, index) in companies"
                :key="index"
                @click.stop="openDetail(each.id)"
                @mouseenter="comMouseEnter(index)"
                @mouseleave="comMouseLeave"
                :style="
                  hoverIndex === index
                    ? 'box-shadow: 1px 2px 8px #17172429'
                    : 'box-shadow: 0px 1px 4px #17172429'
                "
              >
                <div class="flex-container">
                  <div class="flex-container">
                    <img
                      v-if="each.image"
                      class="company-avatar"
                      :src="each.image"
                      alt="com-logo"
                    />
                    <div
                      v-else
                      class="company-avatar d-flex justify-content-center align-items-center"
                    >
                      {{ each.name.charAt(0).toLocaleUpperCase() }}
                    </div>

                    <div>
                      <div class="company-name">{{ each.name }}</div>
                      <div class="company-date">
                        on {{ new Date(each.created_at).toDateString() }}
                      </div>
                    </div>
                  </div>
                  <div class="flex-container" style="position: relative">
                    <div
                      v-if="each.state"
                      class="company-state flex-container"
                      :style="`background-color: ${
                        each.state === 'PENDING'
                          ? '#dfddd9'
                          : each.state === 'ACCEPTED'
                          ? '#e8dff5'
                          : each.state === 'DECLINED'
                          ? '#fae3d4'
                          : ''
                      };border-color: ${
                        each.state === 'PENDING'
                          ? '#5e595c'
                          : each.state === 'ACCEPTED'
                          ? '#411884'
                          : each.state === 'DECLINED'
                          ? '#cc4e00'
                          : ''
                      };color: ${
                        each.state === 'PENDING'
                          ? '#5e595c'
                          : each.state === 'ACCEPTED'
                          ? '#411884'
                          : each.state === 'DECLINED'
                          ? '#cc4e00'
                          : ''
                      };`"
                    >
                      <img
                        v-if="each.state === 'PENDING'"
                        src="/images/execution-icon.png"
                        alt="execution-icon"
                        style="margin-right: 0.5rem; height: 18px; width: 18px"
                      />
                      <img
                        v-if="each.state === 'ACCEPTED'"
                        src="/images/qualified-icon.png"
                        alt="qualified-icon"
                        style="margin-right: 0.5rem; height: 18px; width: 18px"
                      />
                      <img
                        v-if="each.state === 'DECLINED'"
                        src="/images/not-qualified-icon.png"
                        alt="not-qualified-icon"
                        style="margin-right: 0.5rem; height: 18px; width: 18px"
                      />
                      <div style="margin-top: 3px">
                        {{
                          each.state === "PENDING"
                            ? "PENDING"
                            : each.state === "ACCEPTED"
                            ? "ELIGIBLE"
                            : each.state === "DECLINED"
                            ? "NOT ELIGIBLE"
                            : each.state
                        }}
                      </div>
                    </div>

                    <div
                      v-if="
                        each.state &&
                        each.state === 'PENDING' &&
                        hoverIndex === index
                      "
                      class="action-container flex-container"
                      style="justify-content: center"
                      @click.stop="openMenu"
                    >
                      <img
                        :src="
                          openMenuFlag
                            ? '/images/eye-icon.png'
                            : '/images/dot-icon.png'
                        "
                        :alt="openMenuFlag ? 'eye-icon' : 'dot-icon'"
                      />
                    </div>
                    <div
                      class="action-menu"
                      v-if="hoverIndex === index && openMenuFlag"
                    >
                      <div
                        class="action-menu-item"
                        style="margin-bottom: 0.5rem"
                        @click.stop="acceptCompany(each.id)"
                      >
                        Change to Eligible
                      </div>
                      <div
                        class="action-menu-item"
                        @click.stop="declineCompany(each.id)"
                      >
                        Change to Not Eligible
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="all-companies-link"
                @click="$router.push('/companies')"
              >
                See all companies
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6" sm="12" v-if="company_report">
          <div class="content-block">
            <div
              class="flex-container total-title"
              style="padding-bottom: 1rem; border-color: #dfddd9"
            >
              <div class="flex-container">
                <div class="circle" style="background-color: #bab8b9"></div>
                <div>Total Registered Companies</div>
              </div>
              <div>{{ company_report.total }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #5e595c"></div>
                <div>Pending</div>
              </div>
              <div>{{ company_report.pending }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #411884"></div>
                <div>Eligible</div>
              </div>
              <div>{{ company_report.accepted }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #cc4e00"></div>
                <div>Not Eligible</div>
              </div>
              <div>{{ company_report.declined }}</div>
            </div>
            <canvas id="com-chart" class="canvas-w-h"></canvas>
            <div class="all-companies-link" @click="$router.push('/companies')">
              See all companies
            </div>
          </div>
        </b-col>
        <b-col lg="6" sm="12" v-if="application_report">
          <div class="content-block">
            <div
              class="flex-container total-title"
              style="padding-bottom: 1rem; border-color: #dfddd9"
            >
              <div class="flex-container">
                <div class="circle" style="background-color: #bab8b9"></div>
                <div>Total Application</div>
              </div>
              <div>{{ application_report.total }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #234f68"></div>
                <div>Gathering</div>
              </div>
              <div>{{ application_report.gathering }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #41ce98"></div>
                <div>In Screening</div>
              </div>
              <div>{{ application_report.screening }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #411884"></div>
                <div>Qualified</div>
              </div>
              <div>{{ application_report.qualified }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #cc4e00"></div>
                <div>Not Qualified</div>
              </div>
              <div>{{ application_report.disqualified }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #1c9d0b"></div>
                <div>In Execution</div>
              </div>
              <div>{{ application_report.execution }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #741b47"></div>
                <div>Funded</div>
              </div>
              <div>{{ application_report.funded }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #1c9d0b"></div>
                <div>Completed</div>
              </div>
              <div>{{ application_report.completed }}</div>
            </div>
            <div class="flex-container single-title">
              <div class="flex-container">
                <div class="circle" style="background-color: #260b19"></div>
                <div>Canceled</div>
              </div>
              <div>{{ application_report.canceled }}</div>
            </div>
            <canvas id="app-chart" class="canvas-w-h"></canvas>
            <div
              class="all-companies-link"
              @click="$router.push('/applications')"
            >
              See all application
            </div>
          </div>
        </b-col>
      </b-row>

      <decline-reason-modal :company_id="company_id" @refresh-page="query" />
      <accept-company-modal :company_id="company_id" @refresh-page="query" />
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import CompanyService from "../services/company.service";
import ReportsService from "../services/reports.service";
import SettingsService from "../services/settingsDashboard.service";

import DeclineReasonModal from "./Companies/DeclineReasonModal.vue";
import AcceptCompanyModal from "./Companies/AcceptCompanyModal.vue";

export default {
  name: "Dashboard-Page",
  components: { DeclineReasonModal, AcceptCompanyModal },
  data() {
    return {
      raised: null,
      hoverIndex: null,
      openMenuFlag: false,
      querying: false,
      error: null,
      companies: [],
      company_id: null,
      facility_report: null,
      company_report: null,
      application_report: null,
    };
  },
  methods: {
    comMouseEnter(index) {
      this.hoverIndex = index;
    },
    comMouseLeave() {
      this.hoverIndex = null;
      this.openMenuFlag = false;
    },
    openMenu() {
      this.openMenuFlag = !this.openMenuFlag;
    },
    openDetail(id) {
      this.$router.push(`/companies/${id}`);
    },
    acceptCompany(id) {
      this.openMenu();
      this.company_id = id;
      this.$bvModal.show("accept-company-modal");
    },
    declineCompany(id) {
      this.openMenu();
      this.company_id = id;
      this.$bvModal.show("decline-reason-modal");
    },
    query() {
      this.querying = true;

      CompanyService.query({ page: 1, limit: 10 })
        .then(({ count, objects }) => {
          if (!count) {
            this.error = "No companies found.";
          } else {
            this.companies = objects;
          }
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading companies.";
        })
        .finally(() => {
          this.querying = false;
        });
    },
    reports() {
      this.querying = true;
      
      SettingsService.settings().then((data)=>{
        this.raised = data;
      }).catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading facility report.";
        });

      ReportsService.facility()
        .then((data) => {
          this.facility_report = data;
        })
        .catch(({ message }) => {
          this.error = message
            ? message
            : "There was an error loading facility report.";
        })
        .finally(() => {
          ReportsService.companies()
            .then((data) => {
              this.company_report = data;
            })
            .catch(({ message }) => {
              this.error = message
                ? message
                : "There was an error loading companies report.";
            })
            .finally(() => {
              ReportsService.applications()
                .then((data) => {
                  this.application_report = data;
                })
                .catch(({ message }) => {
                  this.error = message
                    ? message
                    : "There was an error loading applications report.";
                })
                .finally(() => {
                  this.querying = false;
                });
            });
        });
    },
  },
  watch: {
    application_report(val) {
      if (val) {
        setTimeout(() => {
          const ctx = document.getElementById("app-chart").getContext("2d");
          const chart = new Chart(ctx, {
            type: "pie",
            data: {
              labels: [
                "Gathering",
                "Screening",
                "Qualified",
                "Disqualified",
                "Execution",
                "Funded",
                "Completed",
                "Canceled",
              ],
              datasets: [
                {
                  data: [
                    val.gathering,
                    val.screening,
                    val.qualified,
                    val.disqualified,
                    val.execution,
                    val.funded,
                    val.completed,
                    val.canceled,
                  ],
                  backgroundColor: [
                    "#cddce5",
                    "#dfddd9",
                    "#e8dff5",
                    "#fae3d4",
                    "#dfddd9",
                    "#ebdfe5",
                    "#c9e2c1",
                    "#260b1919",
                  ],
                },
              ],
            },
            options: {},
          });
        }, 500);
      }
    },
    company_report(val) {
      if (val) {
        setTimeout(() => {
          const ctx = document.getElementById("com-chart").getContext("2d");
          const chart = new Chart(ctx, {
            type: "pie",
            data: {
              labels: ["Pending", "Eligible", "Not Eligible"],
              datasets: [
                {
                  data: [val.pending, val.accepted, val.declined],
                  backgroundColor: ["#dfddd9", "#e8dff5", "#fae3d4"],
                },
              ],
            },
            options: {},
          });
        }, 500);
      }
    },
  },
  mounted() {
    this.query();
    this.reports();
  },
};
</script>

<style scoped>
.canvas-w-h {
  width: auto !important;
  height: auto !important;
  max-height: 300px !important;
}
#dashboard-container {
  padding: 70px 60px;
  background-color: #FAFAFA
}
#dashboard-container .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#dashboard-container .title {
  font-size: 18px;
  font-family: Roboto_Medium;
  color: #8f8b8d;
  margin-bottom: 5px;
}
#dashboard-container .user-role {
  font-size: 14px;
  font-family: Roboto_Medium;
  color: #bab8b9;
  margin-bottom: 25px;
}
#dashboard-container .block {
  border-radius: 5px;
  background-color: #dfddd9;
  padding: 1rem;
  color: #741b47;
  margin-bottom: 1rem;
}
#dashboard-container .label {
  font-size: 14px;
  font-family: Roboto_Medium;
}
#dashboard-container .value {
  font-size: 16px;
  font-family: Roboto_Bold;
}
#dashboard-container .sub-title {
  font-size: 13px;
  height: 1rem;
  display: flex;
  align-items: center;
}
#dashboard-container .content-block {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 2rem 1.5rem;
  box-shadow: 1px 2px 29px #1717241a;
  margin-bottom: 1rem;
}
#dashboard-container .circle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 1rem;
}
#dashboard-container .total-title {
  font-size: 18px;
  font-family: Roboto_Bold;
  color: #260b19;
  border-bottom: 2px solid;
}
#dashboard-container .single-title {
  font-size: 15px;
  color: #5e595c;
  padding: 0.5rem 0rem 0.5rem 0rem;
  margin: 0.5rem 0rem 0.5rem 1.5rem;
  border-bottom: 1px solid #dfddd9;
}
#dashboard-container .map-container {
  height: 250px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px #17172429;
  margin-top: 2rem;
  padding: 1rem;
}
#dashboard-container .company-block {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
#dashboard-container .company-avatar {
  margin-right: 0.5rem;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ebdfe5;
  color: #260b19;
  font-size: 14px;
  font-family: Roboto_Medium;
}
#dashboard-container .company-name {
  font-size: 14px;
  font-family: "Roboto_Medium";
  color: #260b19;
}
#dashboard-container .company-date {
  font-size: 13px;
  color: #bab8b9;
}
#dashboard-container .company-state {
  padding: 0.2rem 0.5rem;
  border: 1px solid #8f8b8d;
  color: #8f8b8d;
  font-size: 11px;
  font-family: "Roboto_Bold";
  border-radius: 5px;
}
#dashboard-container .action-container {
  height: 30px;
  width: 30px;
  margin-left: 0.5rem;
  border-radius: 8px;
  background-color: #dfddd9;
  cursor: pointer;
}
#dashboard-container .action-menu {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #17172429;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 12px;
  right: 0;
  bottom: -60px;
  z-index: 99999;
}
#dashboard-container .action-menu-item {
  cursor: pointer;
  color: #260b19;
  font-size: 13px;
}
#dashboard-container .all-companies-link {
  cursor: pointer;
  color: #741b47;
  font-size: 15px;
  text-align: center;
  margin-top: 2rem;
}
#dashboard-container .all-companies-link:hover {
  text-decoration: underline;
}
</style>
