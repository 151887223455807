<template>
  <div class="step-two-wrapper">
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <div class="data-list" v-if="!managementSystem">
      <div>
        <div class="wrapper-key-value">
          <div class="key-value-container">
            <div class="key-value">
              <div class="key">Analysis Data:</div>
              <div class="value">
                {{ getLastValue(data.ordersCSV) }}
              </div>
              <img
                src="/images/downloadButton.svg"
                alt="Download"
                class="download-img"
                @click="DownloadCSV(data.ordersCSV)" />
            </div>

            <div class="key-value">
              <div class="key">Mapping:</div>
              <div class="value">
                {{ getLastValue(data.ordersConfig) }}
              </div>
              <img
                src="/images/downloadButton.svg"
                alt="Download"
                class="download-img"
                @click="DownloadCSV(data.ordersConfig)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="wrapper-key-value">
        <div class="key-value-container">
          <div v-for="(value, key) in dataTable" :key="key">
            <div v-if="key != 'systemName' && key != 'systemType'" class="key-value">
              <div class="key">{{ key }}:</div>
              <div class="value">
                {{ value }}
              </div>
            </div>
          </div>
          <div>
            <div class="key-value">
              <div class="key">Connected system:</div>
              <div class="system">
                <img :src="`/images/${data.systemName}.svg`" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingService from "@/services/onboarding.service";
import { ErrorMessages } from "../../lib/errorMessages";
import ApplicationsService from "../../services/applications.service";

export default {
  name: "transactions_step",
  props: ["data"],
  data() {
    return {
      error: null,
      managementSystem: false,
      dataTable: {},
    };
  },
  methods: {
    getLastValue(str) {
      if (!str) return "";

      const parts = str.split("/");
      return parts[parts.length - 1] || "order.csv";
    },
    DownloadCSV(path) {
      if (!path) return;
      let payload = { file: path };
      OnboardingService.download(payload)
        .then(({ data }) => {
          if (data) {
            window.open(data, "_blank");
          }
        })
        .catch(({ message }) => {
          this.errorMessage = ErrorMessages.errorDownloading;
        });
    },
  },
  watch: {},
  mounted() {
    if (this.data.systemType == "OTHER") {
      if (!this.data.ordersCSV) this.error = "No data available";
    } else {
      this.managementSystem = true;
      this.dataTable = this.data;
    }
  },
};
</script>

<style scoped>
.system {
  gap: 8px;
  border-radius: 6px;
  width: 100%;
  padding: 10px 16px 10px 16px;
  border: 1px solid #e9e9ea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper-key-value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.key-value-container {
  width: 670px;
  padding: 32px 24px 32px 24px;
  border-radius: 8px;
  background-color: #ffffff;
}

.key-value {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0px 8px 0px;
}

.key {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #394044;
  word-break: break-all;
}

.value {
  width: 100%;
  background-color: #daf3f1;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #024058;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.download-img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.systems-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.step-two-wrapper {
  padding-top: 20px;
}

.management-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.data-list {
  font-family: "Arial", sans-serif;
  padding: 20px;
}

.data-item {
  display: flex;
  margin-bottom: 10px;
  background: white;
  padding: 6px 16px 8px 16px;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  width: 30%;
}

.value {
  word-break: break-all;
}
</style>
