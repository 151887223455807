<template>
  <div class="tt">
    <div v-if="loading" class="loader-overlay">
      <div id="loading-container" class="p-5 d-flex justify-content-center align-items-center">
        <b-spinner></b-spinner>
      </div>
    </div>

    <p v-if="errorMessage && !loading" class="error-block">
      {{ errorMessage }}
    </p>

    <div>
      <b-row>
        <b-col lg="6" sm="12">
          <div>
            <base-input
              label="Give it a name"
              placeholder="Enter Label"
              type="text"
              field="label"
              :value="label"
              @change-value="setValue"></base-input>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" sm="12">
          <base-input
            label="And upload the excel file"
            :placeholder="'Upload your file here'"
            ref="fileInput"
            type="file"
            class="ibra"
            accept=".xlsx"
            :value="uploadedFile ? uploadedFile.name : ''"
            @change-value="handleFileUpload">
          </base-input>
        </b-col>
      </b-row>
    </div>

    <div v-if="showSheets">
      <b-col lg="6" sm="12">
        <base-select
          label="Select Sheet"
          :options="sheets"
          field="selectedSheet"
          :value="selectedSheet"
          @change-value="setSheet"></base-select>
      </b-col>
    </div>

    <div v-if="formShow && !errorMessage">
      <div class="hint">
        <div>
          Drag and drop the column names from your Excel file to match <br />
          them with our required fields.
        </div>
        <div>
          <img src="/images/Text.svg" alt="svg" />
        </div>
      </div>

      <div class="table-header">
        <div>The Required Columns</div>
        <div>Your Excel Columns</div>
      </div>
      <div v-if="!errorMessage" class="demo">
        <div class="w-100">
          <div
            class="key-data"
            :class="{ 'odd-class': index % 2 == 0 }"
            v-for="(item, index) in orderKeys"
            :key="index">
            {{ capitalize(item) }}
          </div>
        </div>
        <div class="">
          <div v-for="(items, index) in groups" :key="index" class="column values-data">
            <Container
              :data-index="index"
              group-name="column"
              :get-child-payload="(itemIndex) => getChildPayload(index, itemIndex)"
              :should-accept-drop="(src, payload) => getShouldAcceptDrop(index, src, payload)"
              :should-animate-drop="(src, payload) => getShouldAnimateDrop(index, src, payload)"
              @drop="onDrop(index, $event)">
              <Draggable v-for="item in items" :key="item.id">
                <div class="draggable-item dragabble-custom" title="Drag to reorder">
                  {{ item.data }}
                  <img src="/images/drag.svg" alt="img" />
                </div>
              </Draggable>
            </Container>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="buttons-block">
        <base-button
          id="myBtn"
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;width:150px"
          @onClick="stepOne ? handleData() : handleSubmitMappingData()"
          :disabled="isSubmitButtonDisabled">
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;width:150px"
          @onClick="closeModal">
          CANCEL
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
//TODO: sould move this to inside the application folder or create new folder for financial pages
import Vue from "vue";
import ApplicationsService from "../services/applications.service";
import { ErrorMessages } from "@/lib/errorMessages";
import { Container, Draggable } from "vue-dndrop";
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
const keyOrder = ["Dates", "Revenues", "CoGS", "R&D", "S&M", "G&A (all others)", "Salaries"];
export default {
  name: "ApplicationFileUploadForm",
  components: {
    Container,
    Draggable,
  },
  props: ["application_id"],
  data() {
    return {
      uploadedFile: null,
      errorMessage: null,
      showSheets: false,
      sheets: [],
      selectedSheet: null,
      formShow: false,
      orderKeys: ["Dates", "Revenues", "CoGS", "R&D", "S&M", "G&A (all others)", "Salaries"],
      groups: [],
      dataKey: [],
      columnNames: [],
      flags: [],
      stepOne: false,
      loading: false,
      label: null,
      sheetLoaded: false,
    };
  },
  created() {
    this.addColumn();
  },
  watch: {
    groups(newValue, oldValue) {
      this.groups = newValue;
    },
  },

  methods: {
    capitalize(s) {
      return s && String(s[0]).toUpperCase() + String(s).slice(1);
    },
    setSheet(value) {
      this.selectedSheet = value;
      this.handleSelectedSheet(value);
    },
    setValue(val, field) {
      this[field] = val;
    },
    async handleFileUpload(event) {
      this.errorMessage = null;
      this.showSheets = false;
      this.formShow = false;
      this.uploadedFile = event;
    },
    async handleSubmitMappingData() {
      if (!this.uploadedFile) {
        this.errorMessage = ErrorMessages.errorUploading;
      }

      if (this.errorMessage) return;

      this.loading = true;
      const xlsxFile = await toBase64(this.uploadedFile);
      const payload = {
        file: xlsxFile,
      };
      const id = this.$route.params.id;

      ApplicationsService.getExcelSheets(payload, id)
        .then((res) => {
          this.showSheets = true;

          let formattedSheets = res.data.map((item) => {
            return { id: item, label: item };
          });
          formattedSheets.unshift({ id: null, label: "Select Sheet" });
          this.sheets = formattedSheets;
          this.stepOne = true;
          this.loading = false;
          this.sheetLoaded = true;
        })
        .catch((err) => {
          this.errorMessage = ErrorMessages.errorSheet;
          this.loading = false;
        });
    },
    async handleSelectedSheet(sheet) {
      this.loading = true;
      //TODO: Should find a way to upload the file once instead of two times
      const xlsxFile = await toBase64(this.uploadedFile);
      const payload = {
        file: xlsxFile,
        sheet: sheet,
      };
      const id = this.$route.params.id;
      this.errorMessage = null;
      ApplicationsService.getSheetRows(payload, id)
        .then((res) => {
          this.columnNames = res.data;
          if (this.columnNames.length < 4) {
            this.loading = true;
            this.formShow = false;
            this.loadingBtn = false;
            this.errorMessage = ErrorMessages.noData;
            return;
          }
          const tempResult = [];
          for (let i = 0; i < this.columnNames.length; i++) {
            tempResult.push({
              id: keyOrder[i] ?? this.columnNames[i] + i,
              data: this.columnNames[i] ?? null,
            });
          }
          this.dataKey = tempResult;
          this.groups = [];
          this.groups.push(tempResult);
          this.formShow = true;
          this.loading = false;
          this.sheetLoaded = false;

          this.$nextTick(() => {
            document.getElementById("myBtn").focus();
          });
        })
        .catch((err) => {
          this.errorMessage = ErrorMessages.noData;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getChildPayload(groupIndex, itemIndex) {
      return this.groups[groupIndex][itemIndex];
    },
    getShouldAcceptDrop(index, sourceContainerOptions, payload) {
      return true;
    },

    //TODO: Can we remove this and put true in the caller instead
    getShouldAnimateDrop(index, sourceContainerOptions, payload) {
      return true;
    },

    onDrop(groupIndex, dropResult) {
      let result = applyDrag(this.groups[groupIndex], dropResult);
      Vue.set(this.groups, groupIndex, result);
    },

    addColumn() {
      this.flags.push({ drop: true, animate: true });
    },

    async handleData() {
      if (this.errorMessage) return;

      this.loading = true;
      const tempResult = {};
      for (let i = 0; i < keyOrder.length; i++) {
        tempResult[keyOrder[i]] = this.groups[0][i]?.data;
      }

      const xlsxFile = await toBase64(this.uploadedFile);
      const payload = {
        file: xlsxFile,
        mapping: tempResult,
        label: this.label,
        sheet: this.selectedSheet,
      };
      const id = this.$route.params.id;

      ApplicationsService.saveMappingData(payload, id)
        .then((res) => {
          this.loading = false;
          this.$emit("financial-submission-successful");
          this.closeModal();
        })
        .catch((err) => {
          this.errorMessage = ErrorMessages.errorUploading;
          this.loading = false;
        });
    },

    closeModal() {
      this.$bvModal.hide("financial-modal");
    },
  },
  computed: {
    isSubmitButtonDisabled() {
      return (
        this.label === null ||
        this.uploadedFile === null ||
        this.loading ||
        this.label.trim() === "" ||
        this.sheetLoaded
      );
    },
  },
};
</script>
<style scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
}
.odd-class {
  display: flex;
  align-items: center;
  background-color: #e3f0ff;
}
.key-details {
  font-size: 15px;
  color: gray;
}

.key-data {
  width: 100%;
  color: #071015;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 11.5px;
  border-radius: 8px;
}

.demo {
  display: flex;
  margin-bottom: 16px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  justify-content: space-between;
}

.dragabble-custom {
  margin-bottom: 6px;
  color: #006ce2;
  width: 230px !important;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  box-shadow: 24px 4px 6px -2px rgba(23, 23, 36, 0.03), 0px 12px 16px -4px rgba(23, 23, 36, 0.08);
  padding: 8px;
  border: 1px solid #cdcfd0;
  /* box-shadow: 0px 1px 2px 0px rgba(23, 23, 36, 0.06), 0px 1px 3px 0px rgba(23, 23, 36, 0.10); */
}

.menu-title {
  font-size: 15px;
  color: #5e595c;
  margin-bottom: 5px;
}
.buttons-block {
  display: flex;
  justify-content: flex-end;
}
.values-data {
  font-size: 20px;
}
.key-details {
  font-size: 15px;
  color: gray;
}

.demo {
  display: flex;
  gap: 75px;
  margin-bottom: 16px;
}
.controls {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}
.controls .title {
  align-self: flex-start;
  margin: 0 1rem;
}
.controls .buttons {
  align-self: flex-end;
  margin: 1rem;
}
.controls .actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 1rem;
}
.controls > div {
  padding-top: 1em;
}
.buttons,
.column-actions {
  display: flex;
  gap: 1rem;
}
.column-actions {
  justify-content: space-evenly;
}
.buttons .button-column {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-left: 5px solid #c4ebaf;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
  transition: border-color 0.2s linear;
  font-family: inherit;
}
.buttons .button-column.remove {
  border-left: 5px solid #dc3545;
}
.buttons .button-column.remove:disabled {
  border-left: 5px solid #e0e0e0;
}
.buttons .button-column.add {
  border-left: 5px solid #c4ebaf;
}
label {
  display: block;
  line-height: 1.6em;
}
.columns {
  display: flex;
  gap: 0.5rem;
  justify-content: stretch;
}
.column {
  flex: 1;
  border-radius: 6px;
  /* border: 1px solid #e0e0e0; */
}
.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.column .dndrop-container.vertical {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}
.error-block {
  color: #721c24;
  font-size: 12px;
  font-weight: 700;
}

.hint {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e3f0ff;
  border-radius: 8px;
  border: 1px solid #c4dcf5;
  padding: 8px 16px 8px 16px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #006ce2;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  color: var(--tertiary-text-color);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
</style>
