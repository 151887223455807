import Vue from "vue";
import Router from "vue-router";

import store from "./store";

import Login from "./pages/auth/Login.vue";
import Logout from "./pages/auth/Logout.vue";
import ForgotPassword from "./pages/auth/ForgotPassword.vue";
import ResetPassword from "./pages/auth/ResetPassword.vue";
import NotFound from "./pages/NotFound.vue";
import TheMainLayout from "./components/Layout/TheMainLayout.vue";
import Dashboard from "./pages/Dashboard.vue";
import Companies from "./pages/Companies/index.vue";
import Company from "./pages/Companies/Company.vue";
import Repayments from "./pages/Repayments.vue";
import Applications from "./pages/Applications/index.vue";
import ApplicationDetail from "./pages/Applications/Detail.vue";
import applicationDetails from "./pages/applicationDetails/index.vue";
import OnboardingApplication from "./pages/OnboardingApplication/index.vue";
import OnboardingApplicationDetails from "./pages/OnboardingApplication/onboardingApplicationDetails.vue";
import Settings from "./pages/Setting/index.vue";
import ExcelMapping from "./pages/ExcelMapping.vue";
import Version from "./pages/Version.vue";
import Help from "./pages/help/index.vue";

//new route for dashboard TEST new design
import Dashboard1 from "./pages/dashboard/index.vue";
// import Notifications from "./pages/Notifications.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    { path: "/", redirect: "/login" },
    {
      name: "login",
      meta: { title: "Login", requiresAuth: false },
      path: "/login",
      component: Login,
    },
    {
      name: "logout",
      meta: { title: "Logout", requiresAuth: true },
      path: "/logout",
      component: Logout,
    },
    {
      name: "forgot-password",
      meta: { title: "Forgot Password?", requiresAuth: false },
      path: "/forgot-password",
      component: ForgotPassword,
    },
    {
      name: "reset-password",
      meta: { title: "Reset Password?", requiresAuth: false },
      path: "/reset-password/:slug",
      component: ResetPassword,
    },
    {
      path: "/",
      component: TheMainLayout,
      children: [
        {
          name: "Dashboard",
          meta: { title: "Dashboard", requiresAuth: true },
          path: "dashboard",
          component: Dashboard1,
        },
        {
          name: "Dashboard",
          meta: { title: "Dashboard", requiresAuth: true },
          path: "dashboard1",
          component: Dashboard,
        },
        {
          name: "Companies",
          meta: { title: "Companies", requiresAuth: true },
          path: "companies",
          component: Companies,
        },
        {
          name: "Company",
          meta: { title: "Company Detail", requiresAuth: true },
          path: "companies/:id",
          component: Company,
        },
        {
          name: "Applications",
          meta: { title: "Applications", requiresAuth: true },
          path: "applications",
          component: Applications,
        },
        {
          name: "ApplicationDetail",
          meta: { title: "Application Detail", requiresAuth: true },
          path: "applications/:id",
          component: ApplicationDetail,
        },
        {
          name: "Repayments",
          meta: { title: "Repayments", requiresAuth: true },
          path: "repayments",
          component: Repayments,
        },
        {
          name: "ApplicationDetails",
          meta: { title: "applicationDetails", requiresAuth: true },
          path: "ApplicationDetails",
          component: applicationDetails,
        },
        {
          name: "OnboardingApplication",
          meta: { title: "OnboardingApplication", requiresAuth: true },
          path: "OnboardingApplication",
          component: OnboardingApplication,
        },
        {
          name: "OnboardingApplicationDerails",
          meta: { title: "OnboardingApplication", requiresAuth: true },
          path: "OnboardingApplication/:id",
          component: OnboardingApplicationDetails,
        },
        {
          name: "excelMapping",
          meta: { title: "excelMapping", requiresAuth: true },
          path: "applications/:id/mapping",
          component: ExcelMapping,
        },
        {
          name: "version",
          meta: { title: "Admin version page", requiresAuth: true },
          path: "version",
          component: Version,
        },
        {
          name: "Settings",
          meta: { title: "Settings", requiresAuth: true },
          path: "settings",
          component: Settings,
        },
        {
          name: "Help",
          meta: { title: "Help", requiresAuth: true },
          path: "help",
          component: Help,
        },
      ],
    },
    {
      name: "not-found",
      meta: { title: "Not Found" },
      path: "/:notFound(.*)",
      component: NotFound,
    },
  ],
});

router.beforeEach((to, _, next) => {
  window.document.title = to.meta.title ? `Capifly - ${to.meta.title}` : "Capifly";

  if (to.meta.requiresAuth && !store.getters.authenticated) {
    sessionStorage.clear();
    next({ name: "login", query: { next: to.fullPath } });
  } else if (!to.meta.requiresAuth && store.getters.authenticated) {
    next("/dashboard");
  } else if (to.path.length > 1) {
    next();
  } else if (store.getters.authenticated) {
    next("/dashboard");
  } else {
    sessionStorage.clear();
    next("/login");
  }
});

router.afterEach(() => {
  const element = document.getElementById("content-container");
  if (element) {
    element.scrollTop = 0;
  }
});

export default router;
