import { render, staticRenderFns } from "./totalApplication.vue?vue&type=template&id=bfc46ec0&scoped=true"
import script from "./totalApplication.vue?vue&type=script&lang=js"
export * from "./totalApplication.vue?vue&type=script&lang=js"
import style0 from "./totalApplication.vue?vue&type=style&index=0&id=bfc46ec0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfc46ec0",
  null
  
)

export default component.exports