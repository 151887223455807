const state = {
  onboardingTab: "all",
};

const mutations = {
  setOnboardingTab(state, tab) {
    state.onboardingTab = tab;
  },
};

const actions = {
  updateOnboardingTab({ commit }, tab) {
    commit("setOnboardingTab", tab);
  },
};

const getters = {
  getOnboardingTab: (state) => state.onboardingTab,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
