<template>
  <b-modal id="change-charts-modal" size="lg" centered hide-header hide-footer>
    <div class="modal-title">Change Application's Charts</div>
    <b-alert :show="error && error.length ? true : false" variant="danger">
      <div v-html="error || '&nbsp;'"></div>
    </b-alert>
    <div v-if="2 === 2" key="2">
      <draggable
        @start="drag = true"
        @end="
          reorder_charts();
          drag = false;
        "
        :list="charts"
        class="draggable-list">
        <b-row v-for="(chart, index) in charts" :key="index" :class="{ [`cursor-grabbing`]: drag }">
          <b-col lg="4" sm="12">
            <base-select
              :label="`Chart ${index + 1}`"
              :options="getChart2Options(chart)"
              field="chart_id"
              :value="chart.id"
              @change-value="(val, field) => setChartValue(val, field, index)"
              :disabled="loading"></base-select>
          </b-col>
          <b-col lg="4" sm="12" v-if="chart.is_metric">
            <base-select
              :options="performanceOptions"
              :cus_style="chart.color ? `background-color:${chart.color};color:#260B19;` : ''"
              style="font-size: 12px; font-family: Roboto_Medium; margin-top: 1.8rem"
              @change-value="(val, field) => changeChartLabelColor(val, field, index)"
              :value="chart.pindex">
            </base-select>
          </b-col>
          <b-col lg="2" sm="12">
            <base-button
              style_variant="secondary"
              cus_style="font-size: 12px; font-family: Roboto_Medium;margin-top:1.8rem"
              :disabled="charts.length === 1"
              @onClick="removeChart(index)">
              REMOVE
            </base-button>
          </b-col>
          <!-- //TODO:  Change this; "row" inside "row" is not a good idea-->
          <b-row v-for="(insight, idx) in chart.insights" :key="idx" lg="10" sm="10">
            <b-col lg="8">
              <base-input
                type="text"
                :field="chart_insights"
                :value="chart.insights[idx]"
                @change-value="(val, field) => setInsight(chart, idx, val)"
                lg="8"></base-input>
            </b-col>
            <b-col lg="2" @click="deleteInsight(chart, idx)"> x </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" sm="5" @click="addInsight(chart)"> + </b-col>
          </b-row>
        </b-row>
      </draggable>
      <b-row>
        <b-col offset-md="4" md="4">
          <base-button
            style_variant="secondary"
            cus_style="font-size: 12px; font-family: Roboto_Medium;margin-bottom:1rem"
            :disabled="loading"
            @onClick="addChart">
            ADD CHART
          </base-button>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col offset-lg="8" lg="4" offset-sm="6" sm="6" class="d-flex align-items-center">
        <base-button
          style_variant="primary"
          cus_style="font-size: 15px; font-family: Roboto_Medium;margin-right:0.5rem;"
          :loading="loading"
          :disabled="loading"
          @onClick="submit">
          SUBMIT
        </base-button>
        <base-button
          style_variant="secondary"
          cus_style="font-size: 15px; font-family: Roboto_Medium"
          :disabled="loading"
          @onClick="closeModal">
          CANCEL
        </base-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ApplicationsService from "../../services/applications.service";
import ChartsService from "../../services/charts.service";
import draggable from "vuedraggable";

export default {
  name: "ChangeChartsModal",
  components: {
    draggable,
  },
  emits: ["refresh-page"],
  props: ["application_data"],
  data() {
    return {
      error: null,
      loading: false,
      chartList: [],
      chart_id: null,
      chart_insights: null,
      charts: [{ id: null, label: null, color: null, is_metric: false }],
      usedCharts: [],
      drag: false,
      performanceOptions: [
        { id: null, label: "Choose" },
        { id: "1", label: "Low performing metric" },
        { id: "2", label: "Moderate performing metric" },
        { id: "3", label: "High performing metric" },
      ],
    };
  },
  methods: {
    async submit() {
      for (let idx in this.charts) {
        let chart = this.charts[idx];
        if (chart.is_metric && chart.label == null) {
          this.error = "Please select rating for every chart";
          return false;
        }
      }

      // Remove empty insights
      this.removeEmptyInsights(this.charts);

      let payload = {
        charts: this.charts,
      };

      if (!this.application_data) return false;

      this.loading = true;
      ApplicationsService.updateApplication(this.application_data.id, payload)
        .then(() => {
          this.$emit("refresh-page");
          this.closeModal();
        })
        .catch(({ message }) => {
          this.error = message ? message : "There was an error updating status.";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removeEmptyInsights(charts) {
      for (let chartIdx in charts) {
        let chart = charts[chartIdx];
        let insightIdx = 0;
        while (insightIdx < chart.insights.length) {
          if (chart.insights[insightIdx] == "") chart.insights.splice(insightIdx, 1);
          else insightIdx++;
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("change-charts-modal");
    },
    setChartValue(val, field, index) {
      if (field === "chart_id") {
        this.charts[index]["id"] = val;
        this.charts[index]["is_metric"] = this.chartList[val].is_metric;
        this.usedCharts.push(val);
      }
      if (field === "chart_label") this.charts[index]["label"] = val;
      if (field === "chart_color") this.charts[index]["color"] = val;
    },
    setInsight(chart, idx, val) {
      chart.insights[idx] = val;
    },
    deleteInsight(chart, idx) {
      if (chart.insights) chart.insights.splice(idx, 1);
    },

    addInsight(chart) {
      if (chart.insights) {
        chart.insights.push("");
      } else chart.insights = [""];
    },

    getChart2Options(chart) {
      let chartsOptions = [{ id: null, label: "Select Any" }];
      for (let chartId in this.chartList) {
        // if (this.charts.some((c) => c.id == chartId)) continue;
        if (chart.id != chartId && this.usedCharts.includes(chartId)) continue;

        let nchart = this.chartList[chartId];
        let name = `${nchart.name} - ${nchart.industry}`;
        if (nchart.segment) name += " - " + nchart.segment;
        chartsOptions.push({ id: chartId, label: name });
      }
      return chartsOptions;
    },
    addChart() {
      let maxOrder = this.charts
        .map((item) => item.order)
        .reduce((prev, curr) => {
          return Math.max(prev, curr);
        }, 0);
      let newChartOrder = maxOrder + 1;
      this.charts.push({ id: null, label: null, color: null, order: newChartOrder, insights: [] });
    },
    removeChart(index) {
      let chart = this.charts.splice(index, 1);
      this.usedCharts.remove(chart.id);
    },
    reset() {
      this.error = null;
      this.loading = false;
      this.chart_id = null;
      this.charts = [{ id: null, label: null, color: null, is_metric: false }];
    },
    reorder_charts() {
      this.charts.forEach((chart, index) => {
        chart.order = index;
      });
    },
    changeChartLabelColor(val, field, index) {
      this.charts = this.charts.map((item) => this.addPerformance(item));
      const color = val == 1 ? "#FAE3D4" : val == 2 ? "#D7D7D7" : "#E8DFF5";
      const label = this.performanceOptions[val]?.label?.replace(/\([^)]+\)\s/, "");
      if (color && label) {
        this.charts[index]["color"] = color;
        this.charts[index]["label"] = label;
      }
    },
    addPerformance(item) {
      if (item.pindex || !item.label) return item;
      item.pindex = 0;
      const performance = this.performanceOptions.find((perf) => {
        return perf.label === item.label;
      });
      if (performance) {
        item.pindex = performance.id;
      } else {
        if (item.label.startsWith("Moderate")) {
          item.pindex = item.color == "#FAE3D4" ? 2 : 3;
        } else {
          this.performanceOptions.push({
            id: this.performanceOptions.length,
            label: item.label + "         (Legacy)",
          });
          item.pindex = this.performanceOptions.length - 1;
        }
      }
      return item;
    },
  },
  watch: {
    application_data(newValue, oldValue) {
      this.charts = newValue?.charts.map((item) => {
        return this.addPerformance({
          id: item.id,
          name: item.name,
          industry: item.industry,
          segment: item.segment,
          is_metric: item.is_metric,
          color: item.color,
          label: item.label,
          insights: item.insights,
          order: item.order,
        });
      });
    },
  },
  mounted() {
    this.loading = true;
    this.charts = this.application_data?.charts2.map((item) =>
      this.addPerformance({
        id: item.id,
        name: item.name,
        industry: item.industry,
        segment: item.segment,
        is_metric: item.is_metric,
        color: item.color,
        label: item.label,
        insights: item.insights,
        order: item.order,
      })
    );

    ChartsService.query()
      .then((objects) => {
        this.chartList = {};

        objects.forEach((chart, idx) => {
          this.chartList[chart.id] = chart;
        });
      })
      .catch(({ message }) => {
        this.error = message;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
#change-charts-modal .modal-title {
  font-size: 24px;
  font-family: Roboto_Medium;
  color: #5e595c;
  border-bottom: 2px solid #dfddd9;
  margin-bottom: 2rem;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.draggable-list > div {
  cursor: pointer;
}
</style>
